import '../css/typography.css';
import '../css/layout.css';
import '../css/utils.css';

import CookieConsent from 'react-cookie-consent';
import Footer from './footer';
import Header from './header';
import Image from './image';
import React from 'react';
import globals from '../utils/variables';
import styled from 'styled-components';

const ScBackground = styled.div`
  animation: 50s linear 0s infinite bp;
  height: 100%;
  width: 100%;
`;

const OuterWrapper = styled.div`
  margin: auto;
  flex: 1;
  overflow: auto;
  padding-top: 3.5rem;
`;

const Main = styled.div`
  min-height: calc(100vh - 3.5rem - 292px);
  background: #fafafa;
  z-index: 200;
`;

const ScCookieConsent = styled.div`
  & .CookieConsent {
    background: #ddd !important;
    color: #222 !important;
    border-top: 3px solid #aaa;
    align-items: center !important;
  }

  & #rcc-confirm-button {
    background: ${globals.mainColor} !important;
    border: 2px solid ${globals.mainColor} !important;
    border-radius: 5px !important;
    color: #fff !important;
    transition: all 0.5s;
  }
  & #rcc-confirm-button:hover {
    background: transparent !important;
    color: ${globals.mainColor} !important;
    border: 2px solid ${globals.mainColor} !important;
  }

  & a:hover {
    text-decoration: underline;
  }
`;

export default ({ children, onCookieAccept }) => (
  <ScBackground>
    <OuterWrapper>
      <div className="FlexBoxCol">
        <Header />
        <Main>{children}</Main>
        <Footer />
        {/* <ScCookieConsent>
          <CookieConsent
            location="bottom"
            buttonText="Akzeptieren"
            declineButtonText="Ablehnen"
            onAccept={onCookieAccept}
            cookieName="gatsby-gdpr"
          >
            Diese Seite verwendet Cookies, um Ihnen das bestmögliche Erlebnis zu
            gewährleisten. Mit der weiteren Benutzung dieser Website, stimmen
            Sie unserer Cookie-Nutzung und unseren{' '}
            <a style={{ color: globals.mainColor }} href="/privacy-policy">
              Datenschutzrichtlinien
            </a>{' '}
            zu.
          </CookieConsent>
        </ScCookieConsent> */}
      </div>
    </OuterWrapper>
  </ScBackground>
);
