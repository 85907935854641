import React from 'react';

export default ({ size = '3.5rem' }) => (
  <svg
    version="1.1"
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={size}
    height={size}
    viewBox="50 50 900 900"
    enable-background="new 0 0 1000 1000"
  >
    <line
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      x1="150"
      y1="280"
      x2="150"
      y2="880"
    />

    <line
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      x1="150"
      y1="280"
      x2="383"
      y2="280"
    />

    <line
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      x1="382"
      y1="880"
      x2="382"
      y2="280"
    />

    <line
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      x1="150"
      y1="880"
      x2="850"
      y2="880"
    />
    <path
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      d="
  M150,280c0-96.36,156.665-174.333,350.272-174.333"
    />

    <line
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      x1="850"
      y1="280"
      x2="850"
      y2="880"
    />
    <path
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      d="
  M850,280c0-96.36-154.902-174.333-346.333-174.333"
    />

    <line
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      x1="616"
      y1="280"
      x2="616"
      y2="880"
    />

    <line
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      x1="617"
      y1="280"
      x2="850"
      y2="280"
    />
    <path
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-miterlimit="10"
      d="M850,880"
    />

    <line
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      x1="382"
      y1="280"
      x2="382"
      y2="880"
    />

    <line
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      x1="383"
      y1="280"
      x2="616"
      y2="280"
    />

    <line
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      x1="616"
      y1="880"
      x2="616"
      y2="280"
    />

    <line
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      x1="383"
      y1="530"
      x2="617"
      y2="280"
    />

    <line
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      x1="500"
      y1="106"
      x2="500"
      y2="280"
    />

    <line
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      x1="499.272"
      y1="280.078"
      x2="316"
      y2="131.675"
    />

    <line
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      x1="500.272"
      y1="280.078"
      x2="684"
      y2="131.098"
    />

    <line
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      x1="500"
      y1="880"
      x2="500"
      y2="405"
    />
    <path
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      d="
  M440.5,471"
    />
    <path
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      d="
  M440.5,880"
    />
    <path
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      d="
  M557.5,880"
    />

    <line
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      x1="733"
      y1="880"
      x2="733"
      y2="458"
    />

    <line
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      x1="265"
      y1="880"
      x2="265"
      y2="455"
    />

    <rect
      x="226.109"
      y="361.109"
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -205.2254 304.5388)"
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      width="77.782"
      height="77.781"
    />

    <rect
      x="695.109"
      y="361.109"
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -67.8591 636.1736)"
      fill="none"
      stroke="#FFFFFF"
      stroke-width="30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      width="77.782"
      height="77.782"
    />
  </svg>
);
