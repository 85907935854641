import {
  IoCloseCircle,
  IoCloseCircleOutline,
  IoMenuOutline,
} from 'react-icons/io5';
import React, { useState } from 'react';

import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { ScFlexCenter } from './styled-components';
import globals from '../utils/variables';
import styled from 'styled-components';

const ScMenu = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  color: #fff;
  cursor: pointer;

  @media (max-width: 1000px) {
    display: flex;
  }

  &:hover {
    color: #fff;
  }
`;

const ScClose = styled.div`
  /* display: inline-flex; */
  align-items: center;
  justify-content: center;
  /* padding: 0 0.5rem; */
  color: #fff;
  cursor: pointer;
  height: 2.5rem;
  width: 2.5rem;
  /* margin-bottom: 1rem; */
  &:hover {
    color: #fff;
  }
`;

const ScNavigation = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  flex-direction: column;
  padding: 0.5rem;
  /* justify-content: flex-end;
  align-items: flex-start; */
  z-index: 9999;
  transition: all 500ms;
  background: ${globals.secondaryColorTransparent};

  width: 30rem;
  max-width: 100%;
  height: 100vh;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(10px) saturate(100%) contrast(45%) brightness(130%);
  /* transform: translateX(-100%); */
  transform: ${props => (props.open ? 'translateX(0%)' : 'translateX(-100%)')};

  @media (max-width: 1000px) {
    display: flex;
  }
`;

const ScLink = styled(AnchorLink)`
  display: block;
  position: relative;
  color: #fff;
  padding: 0.5rem;
  margin: 0 0.5rem;
  text-decoration: none !important;
  z-index: -1;
  transition: all 0.3s;
  /* border-bottom: 3px solid #fff; */

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 0.1s;
    background: #fff;
    content: ' ';
    height: 3px;
    border-radius: 3px;
    width: 0%;
  }
  &:hover {
    color: #fff;
    &::after {
      width: 100%;
    }
  }
`;

const ScEmphasizedLink = styled.a`
  display: block;
  flex: 1;
  color: ${globals.mainColor};
  border-radius: 2rem;
  padding: 0.35rem 0.75rem;
  margin: 0 1rem;
  transition: all 0.3s;
  text-decoration: none;
  background: #fff;
  border: 2px solid #fff;

  &:hover {
    border: 2px solid #fff;
    background: ${globals.mainColor};
    color: #fff;
  }
`;

function NavigationMobile() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <ScMenu onClick={() => setOpen(true)}>
        <IoMenuOutline size="3rem" />
      </ScMenu>
      <ScNavigation open={open}>
        <ScFlexCenter style={{ marginBottom: '1rem' }}>
          <ScClose onClick={() => setOpen(false)}>
            <IoCloseCircleOutline size="2.5rem" />
          </ScClose>
          <ScEmphasizedLink href="mailto:maria@gaestehaus-rath.at?subject=Buchung">
            Buchen
          </ScEmphasizedLink>
        </ScFlexCenter>
        <ScLink onAnchorLinkClick={() => setOpen(false)} to="/#furnishing">
          Ausstattung
        </ScLink>
        <ScLink onAnchorLinkClick={() => setOpen(false)} to="/#price">
          Preis
        </ScLink>
        <ScLink onAnchorLinkClick={() => setOpen(false)} to="/#map">
          Anfahrt
        </ScLink>
      </ScNavigation>
    </>
  );
}
export default NavigationMobile;
