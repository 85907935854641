import Image from './image';
import { Link } from 'gatsby';
import Logo from './logo.js';
import Navigation from './navigation';
import NavigationMobile from './navigation-mobile';
import React from 'react';
import globals from '../utils/variables';
import styled from 'styled-components';

const ScHeader = styled.div`
  background: ${globals.secondaryColorTransparent};
  /* background: rgba(0, 0, 0, 0.92); */

  position: fixed;
  top: 0;
  width: 100%;
  height: 3.5rem;
  z-index: 1000;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(10px) saturate(100%) contrast(45%) brightness(130%);
`;

const ScHeader3 = styled.div`
  flex: 1;
  color: '#fff';
  font-family: 'ArimaMadurai';
  padding-left: 1rem;
  /* font-weight: bold; */
`;

const ScSubTitle = styled.div`
  color: '#fff';
  font-family: 'ArimaMadurai';
  font-size: 80%;
`;
const ScMaxWidthContainer = styled.div`
  max-width: 1280px;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default () => (
  <ScHeader>
    <ScMaxWidthContainer>
      <NavigationMobile />
      <ScHeader3>
        <Link
          to="/"
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            width: 'auto',
            top: '0rem',
          }}
        >
          <Logo size="3rem" />
          <div
            style={{
              marginLeft: '0.5rem',
              marginTop: '0.55rem',
              color: '#fff',
              fontFamily: 'ArimaMadurai',
            }}
          >
            Ferienwohnung<ScSubTitle>F&M Rath</ScSubTitle>
          </div>
        </Link>
      </ScHeader3>
      <Navigation />
    </ScMaxWidthContainer>
  </ScHeader>
);
