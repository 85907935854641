import Image from './image';
import React from 'react';
import globals from '../utils/variables';
import styled from 'styled-components';

export const ScHeader3 = styled.h1`
  position: relative;
  display: inline-block;
  font-size: 16pt;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  color: ${globals.mainColor};

  &::after {
    position: absolute;
    bottom: -1rem;
    left: 0;
    transition: all 0.1s;
    background: ${globals.mainColor};
    content: ' ';
    height: 4px;
    border-radius: 10px;
    width: 100%;
  }
`;

export const ScHeader4 = styled.h2`
  position: relative;
  display: inline-block;
  font-size: 12pt;
  margin-top: 0.5rem;
  margin-bottom: 1.25rem;
  /* color: ${globals.mainColor}; */

  &::after {
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    transition: all 0.1s;
    background: #555;
    content: ' ';
    height: 3px;
    border-radius: 6px;
    width: 100%;
  }
`;

export const ScSeparator = styled.div`
  border-bottom: 1px solid #ccc;
  width: 100%;
`;

export const ScFlexAlignCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ScFlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ScFlexColCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ScFlexItem = styled.div`
  flex: 1;
  min-width: 0;
`;

export const ScColumnsResponsive = styled.div`
  display: flex;
  width: 100%;
  justify-content: stretch;
  align-items: stretch;

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
    width: 100%;
  }
`;

export const ScMaxWidthContainer = styled.div`
  position: relative;
  max-width: 1280px;
  height: 100%;
  margin: auto;
  z-index: 100;
  padding: 0 1rem;
`;

export const ScMaxWidthContainerWide = styled.div`
  position: relative;
  max-width: 1600px;
  height: 100%;
  margin: auto;
  z-index: 100;
`;

export const ScBox = styled.div`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
`;

export const ScBoxClickable = styled.div`
  /* margin: 1rem; */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
  /* border: 2px solid #fff; */

  &:hover {
    /* border: 3px solid ${globals.mainColor}; */
    /* margin: calc(1rem - 3px); */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    cursor: pointer;
  }
`;

export const ScTextBox = styled.div`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 0.5rem;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
`;

export const ScBoxHeader3 = styled.h1`
  bottom: 0;
  width: 100%;
  padding: 1rem;
  font-size: 16pt;
  color: ${globals.mainColor};
  background: #fff;
  border-top: 3px solid ${globals.mainColor};
  margin-bottom: 0;
  z-index: 100;
`;

export const ScLogo = styled.div`
  font-family: 'AR Bonnie';
  display: inline-block;
  color: ${globals.mainColor};
  font-weight: bold;
  /* margin-left: 1rem; */
  font-size: 38pt;
  width: 3rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: 'BEA';
  }
`;

export const ScImage = styled(Image)`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 3px;
  max-width: calc(100vw - 4rem);
  max-height: calc(50vh - 2rem);
`;

export const ScTextSmall = styled.span`
  font-size: 80%;
`;
