import {
  IoCall,
  IoCallOutline,
  IoGlobe,
  IoGlobeOutline,
  IoMailOpen,
  IoMailOpenOutline,
  IoMap,
  IoMapOutline,
} from 'react-icons/io5';
import {
  ScFlexAlignCenter,
  ScFlexCenter,
  ScHeader3,
  ScHeader4,
  ScImage,
} from './styled-components';

import { Link } from 'gatsby';
import React from 'react';
import globals from '../utils/variables';
import styled from 'styled-components';

const ScFooter = styled.div`
  background: #eee;
  display: flex;
`;

const ScMaxWidthContainer = styled.div`
  position: relative;
  max-width: 1280px;
  width: 100%;
  height: 100%;
  margin: auto;
  z-index: 100;
`;

const ScColumns = styled.div`
  display: flex;
  /* padding: 0 1rem; */
  @media (max-width: 1000px) {
    flex-direction: column;
    width: 100%;
  }
`;

const ScContainer = styled.div`
  margin: 1rem;
  flex: 1;
  width: 33%;
  @media (max-width: 1000px) {
    width: calc(100% - 2rem);
  }

  a:hover {
    text-decoration: underline !important;
  }
`;

export default () => (
  <ScFooter>
    <ScMaxWidthContainer>
      <ScColumns>
        <ScContainer>
          <ScHeader4>Ferienwohnung F&M Rath</ScHeader4>
          <div>
            <a href="https://goo.gl/maps/K8ZKLvokahJFWV4eA">
              Kleinsteinbach 2
              <br /> 8283 Bad Blumau
            </a>
          </div>
        </ScContainer>
        <ScContainer>
          <ScHeader4>Links</ScHeader4>
          <p>
            <Link to="/imprint">Impressum</Link>
            <br />
            <Link to="/privacy-policy">Datenschutzerklärung</Link>
          </p>
          <p>
            <a href="https://bad-blumau.com/">Thermengemeinde Bad Blumau</a>
            <br />
            <a href="https://www.heiltherme.at/">
              Quellenhotel Heiltherme Bad Waltersdorf
            </a>
            <br />
            <a href="https://www.blumau.com/">
              Hundertwasser Therme - Rogner Bad Blumau
            </a>
            <br />
            <a href="https://www.therme.at/">Thermenresort Loipersdorf</a>
            <br />
            <a href="https://oststeiermark.at/">Oststeiermark</a>
            <br />
            <a href="https://www.vulkanland.at/">Steirisches Vulkanland</a>
            <br />
          </p>
          {/* <a href="https://www.neuropraxis-poelzl.at/">Dr. Stephan Pölzl</a>
          <br />
          <br />
          <a href="https://www.wko.at/site/ImpulsPro/start.html">
            <ScFlexAlignCenter>
              WKO ImpulsPro
              <ScImage
                style={{ width: '3.4em', height: '4.1em', marginLeft: '1em' }}
                src="pictures/Impuls_pro_neu.jpg"
              />
            </ScFlexAlignCenter>
          </a> */}
        </ScContainer>
        <ScContainer>
          <ScHeader4>Kontakt</ScHeader4>
          <div>
            <div>
              <IoCall style={{ marginRight: '0.5rem' }} />
              <a href="tel:+43 664 49 50 184">+43 664 49 50 184</a>
            </div>
            <div>
              <IoMailOpen style={{ marginRight: '0.5rem' }} />
              <a href="mailto:maria@gaestehaus-rath.at">
                maria@gaestehaus-rath.at
              </a>
            </div>
            <div>
              <IoGlobeOutline style={{ marginRight: '0.5rem' }} />
              <a href="http://gaestehaus-rath.at//">www.gaestehaus-rath.at</a>
            </div>
          </div>
        </ScContainer>
      </ScColumns>
      <p style={{ paddingTop: '1rem', margin: '1rem', fontSize: '8pt' }}>
        © 2022 by Ferienwohnung F&M Rath
      </p>
    </ScMaxWidthContainer>
  </ScFooter>
);
