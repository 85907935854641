export default {
  mainColor: '#12584e', //'hsl(1,86%,36%)', // #3CC47C
  secondaryColor: 'hsl(172,16%,73%)', //'#c6a9a3'
  secondaryColorTransparent: 'rgb(18,88,78, 0.85)', //'#c6a9a3'
  keywords: [
    'Maria Rath',
    'Gaestehaus',
    'F&M Rath',
    'Kleinsteinbach',
    'Bad Blumau',
    'Ferienwohnung',
    'Apartment',
  ],
};
